
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    cardTitle: {
        padding: theme.spacing(3),
        paddingBottom: 0,
    },
    cardTypography: {
        fontWeight: '600',
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            zIndex: 1,
            width: '75px',
            height: '2px',
            background: '#1242de',
            marginTop: '7px'
        }
    }
}))

export { useStyles };