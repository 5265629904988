import React from 'react';
import { Helmet } from "react-helmet-async";

import { APP_NAME } from '../../_helpers';

import { Grid, Typography } from '@mui/material';

import ErrorImage from './404.svg'
import { useStyles } from '.'

function NotFound() {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | Not Found`}</title>
            </Helmet>
            <Grid className={classes.notFoundWrapper} container spacing={0} direction="column" alignItems="center" justifyContent="center" >
                <Grid item xs={4}>
                    <img src={ErrorImage} alt="Page not found" />
                    <Typography variant="h4">Awww...<span className={classes.textWrongTurn}>Wrong Turn</span></Typography>
                    <Typography variant="h1">Page <span className={classes.textNotFound}>Not Found</span></Typography>
                    <Typography component="h1" variant="caption">The page you are looking for doesn't exist. Go back to choose a new direction.</Typography>
                </Grid>

            </Grid>
        </React.Fragment>
    )
}


// const connectedNotFound = connect(null, null)(NotFound);
export { NotFound };
