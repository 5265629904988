

import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { connect, useDispatch } from 'react-redux';
import { GlobalAction } from '../../redux/actions';

function Toast({ message }) {

    const [isOpen, setIsOpen] = useState(message ? true : false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsOpen(message ? true : false)
        return () => {
            setTimeout(() => {
                dispatch(GlobalAction.showToastMessage(null))
            }, 2000);
        };
        //eslint-disable-next-line
    }, [message])

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Snackbar
            open={isOpen}
            onClose={handleClose}
            TransitionComponent={Slide}
            message={message}
            key={Slide.name}
        />
    );
}


const connectedToast = connect(null)(Toast);
export { connectedToast as Toast };

