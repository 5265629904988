import {
    BRAND_DETAILS_REQUEST, BRAND_DETAILS_SUCCESS, BRAND_DETAILS_FAILURE,
    GET_BRAND_LATEST_STORY_REQUEST, GET_BRAND_LATEST_STORY_SUCCESS, GET_BRAND_LATEST_STORY_FAILURE,
    GET_BRAND_LATEST_POST_REQUEST, GET_BRAND_LATEST_POST_SUCCESS, GET_BRAND_LATEST_POST_FAILURE,
    QR_SCAN_COUNT_REQUEST, QR_SCAN_COUNT_SUCCESS, QR_SCAN_COUNT_FAILURE
} from '../actions';

const initialState = {
    brand_loading: true, brand_request: {}, brand: {}, brand_errors: {},
    latest_story_loading: false, latest_story_error: {},
    latest_post_loading: false, latest_post: null, latest_post_error: {},
    qr_scan_count_loading: false, qr_scan_count_error: {}
};

export function BrandReducer(state = initialState, action) {
    switch (action.type) {
        case BRAND_DETAILS_REQUEST:
            return {
                ...state,
                brand_loading: true,
                brand_request: action.request,
            };
        case BRAND_DETAILS_SUCCESS:
            return {
                ...state,
                brand_loading: false,
                brand: action.data,
            };
        case BRAND_DETAILS_FAILURE:
            return {
                ...state,
                brand_loading: false,
                brand_errors: action.error,
            };

        /** begin::Sign in redux */
        case GET_BRAND_LATEST_STORY_REQUEST: {
            return {
                ...state,
                latest_story_loading: true,
                latest_story_request: action.request,
                latest_story_error: {}
            };
        }
        case GET_BRAND_LATEST_STORY_SUCCESS: {
            return {
                ...state,
                latest_story_loading: false,
                brand: { ...state.brand, story: action.data },
            };
        }
        case GET_BRAND_LATEST_STORY_FAILURE: {
            return {
                ...state,
                latest_story_loading: false,
                latest_story_error: action.errors,
            };
        }
        /** end::Sign in redux */
        /** begin::Sign in redux */
        case GET_BRAND_LATEST_POST_REQUEST: {
            return {
                ...state,
                latest_post_loading: true,
                latest_post_request: action.request,
                latest_post_error: {}
            };
        }
        case GET_BRAND_LATEST_POST_SUCCESS: {
            return {
                ...state,
                latest_post_loading: false,
                latest_post: action.data.url,
                brand: {
                    ...state.brand, recent_post: action.data
                }
            };
        }
        case GET_BRAND_LATEST_POST_FAILURE: {
            return {
                ...state,
                latest_post_loading: false,
                latest_post_error: action.errors,
            };
        }
        /** end::Sign in redux */

        /** begin::Qr scan count redux */
        case QR_SCAN_COUNT_REQUEST:
            return {
                ...state,
                qr_scan_count_loading: true,
                qr_scan_count_request: action.request,
            };
        case QR_SCAN_COUNT_SUCCESS:
            return {
                ...state,
                qr_scan_count_loading: false,
            };
        case QR_SCAN_COUNT_FAILURE:
            return {
                ...state,
                qr_scan_count_loading: false,
                qr_scan_count_errors: action.error,
            };

        /** end::Qr scan count redux */
        default:
            return state;
    }
}

