import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet-async";

import { PreSignIn } from '../../../_layouts/pre-signin';
import { PreAuthRoutes } from '../../../routes';

import { AppNameContext } from '../../../_contexts';
import { StorySection } from '../../components/story-section';
import { Grid } from '@mui/material';
import { HomeVerifyInstaHandleForm } from '.';
import { useLocation } from 'react-router-dom';
import { BrandAction } from '../../../redux/actions';

function HomeVerifyInstaHandle({ isBrandLoading, brand, signIn, signinErrors, qrScanCount, ...props }) {

    const appName = useContext(AppNameContext);

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    let qrScanned = query.get('qr'); // Shopper coupon code

    useEffect(() => {
        const subdomain = window.location.host.split('.')[0];

        if (qrScanned && qrScanned==='1') {
            qrScanCount({ subdomain: subdomain })
        }
    }, [])
    

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${appName} | ${PreAuthRoutes('home').name}`}</title>
            </Helmet>
            <PreSignIn>
                <Grid container spacing={0} display="flex" alignItems="center" justifyContent={`center`}>
                    <HomeVerifyInstaHandleForm loading={isBrandLoading} />
                    <StorySection src={brand.sample_story} alt={brand.sample_story} loading={isBrandLoading} />
                </Grid>
            </PreSignIn>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    isBrandLoading: state.BrandReducer.brand_loading,
    brand: state.BrandReducer.brand,
    brand_errors: state.BrandReducer.brand_errors,
})

function mapDispatchToProps(dispatch) {

    return {
        qrScanCount: (params) => dispatch(BrandAction.qrScanCount(params)),
    }
  };
  
const connectedHomeVerifyInstaHandle = connect(mapStateToProps, mapDispatchToProps)(HomeVerifyInstaHandle);
export { connectedHomeVerifyInstaHandle as HomeVerifyInstaHandle };
