import { Box } from '@mui/system'
import React from 'react'
import { AnchorLink } from '../../../_components/controls'

function BrandLogo({ src, alt, href }) {
    if (src) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 4 }} >
                <AnchorLink
                    label={
                        <img src={src} alt={alt} style={{ maxWidth: '200px', height: `unset` }} />
                    }
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                />
            </Box>
        )
    } else {
        return false;
    }
}

export { BrandLogo }
