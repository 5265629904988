import { apiServices } from '../../_helpers';


export const BrandServices = {

	/**
	 * Serivice to get brand details based on subdomain
	 * @param {sting} subdomain - Subdomain of the brand from url 
	 * @author Akshay 
	 */
	getBrandDetails: (subdomain) => {
		return apiServices.get(`/ns/pre-auth/deal/${subdomain}`)
			.then((response) => {
				return response;
			})
	},
	/**
	 * Serivice to get brand details based on subdomain
	 * @param {sting} subdomain - Subdomain of the brand from url 
	 * @author Akshay 
	 */
	getBrandRecentPost: (subdomain) => {
		return apiServices.get(`/ns/pre-auth/latest-post/${subdomain}`)
			.then((response) => {
				return response;
			})
	},

	/**
	 * Serivice to get brand details based on subdomain
	 * @param {sting} subdomain - Subdomain of the brand from url 
	 * @author Akshay 
	 */
	qrScanCount: (params) => {
		return apiServices.post(`/ns/pre-auth/qr-scan-count`, params)
			.then((response) => {
				return response;
			})
	}

}
