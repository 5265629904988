
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    alert: {
        marginBottom: theme.spacing(1),
        '& .MuiPaper-root': {
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`
        }
    }
}))

export { useStyles };