
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { cookieServices } from '../../_helpers';
import { Box, Grid } from '@mui/material';
import { Card, CardContent } from './../../_components/card'
import { styled } from "@mui/system";


// Styled component for the left grid 
const PreSignInWrapper = styled(({ ...props }) => (<Box display="flex" alignItems="center" justifyContent="center" {...props} />))`
    & .PreSignInWrapperCardContainer {
        background:  #000;
    }
    & .PreSignInWrapperGridContainer {
        height: 100vh;
        ${props => props.theme.breakpoints.down(700)} {
            align-items: 'flex-start !important',
        }
    }
  `;



const PreSignIn = ({ isBrandLoading, brand, children, ...props }) => {

    let history = useHistory();

    useEffect(() => {

        if (cookieServices.get('accessToken'))
            history.push('/');


    }, [history])

    return (
        <PreSignInWrapper brand={brand} className="white">
            <Grid container spacing={0} display="flex" alignItems="center" justifyContent="center" className="PreSignInWrapperGridContainer">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center">
                    <Card style={{ boxShadow: `none` }}>
                        <CardContent style={{ padding: 0, display: `flex`, alignItems: `center`, justifyContent: `center` }} className="PreSignInWrapperCardContainer">
                            {children}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </PreSignInWrapper>
    )
};
const mapStateToProps = (state) => ({
    brand: state.BrandReducer.brand,
})

const connectedPreSignIn = connect(mapStateToProps, null)(PreSignIn);
export { connectedPreSignIn as PreSignIn };





