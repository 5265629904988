import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { BrandAction } from '../../../redux/actions';

// Styled component for the left grid 
const RightGridItem = styled(({ ...props }) => (<Grid item display="flex" justifyContent="center" alignItems="center" {...props} />))`
        & .MuiGrid-item {
            background: #fff; 
        }
`;

function RecentPost({ getBrandRecentPost, latest_post, brand }) {


    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const subdomain = window.location.host.split('.')[0];
        getBrandRecentPost(subdomain).then((response) => {
            setLoading(false);
        });

        document.body.classList.add('white');

        //eslint-disable-next-line
    }, []);



    if (loading) {
        return (
            <RightGridItem brand={brand} >
                <Grid container spacing={0} sx={{ height: `100%` }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center"  >
                        <Skeleton width={350} height={708} animation="wave" />
                    </Grid>
                </Grid>
            </RightGridItem>
        )
    } else {
        return (
            <RightGridItem brand={brand} >
                <Grid container spacing={0} sx={{ height: `100%` }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center">
                        <iframe title="insta story" width="350" height="608" src={latest_post} frameBorder="0" ></iframe>
                    </Grid>
                </Grid>
            </RightGridItem>
        )
    }
}

const mapStateToProps = (state) => ({
    brand: state.BrandReducer.brand,
    latest_post: state.BrandReducer.latest_post,
})

function mapDispatchToProps(dispatch) {
    return {
        getBrandRecentPost: (subdomain) => dispatch(BrandAction.getBrandRecentPost(subdomain)),
    }
};


const connectedRecentPost = connect(mapStateToProps, mapDispatchToProps)(RecentPost);
export { connectedRecentPost as RecentPost };


