import { Box } from '@mui/system'
import React from 'react'
import { connect } from 'react-redux';
import { AnchorLink } from '../../../_components/controls';

function InstaGlyphIcon({ brand, src = null }) {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" pt={2} >
            <AnchorLink
                label={src ? src : `https://www.instagram.com/${brand.insta_handle}`}
                href={src ? src : `https://www.instagram.com/${brand.insta_handle}`}
                target="_blank"
                rel="noreferrer"
            />
        </Box>
    )
}


const mapStateToProps = (state) => ({
    brand: state.BrandReducer.brand,
})

const connectedInstaGlyphIcon = connect(mapStateToProps, null)(InstaGlyphIcon);
export { connectedInstaGlyphIcon as InstaGlyphIcon };

