import { BrandServices } from "../services";

export const BRAND_DETAILS_REQUEST = 'BRAND_DETAILS_REQUEST';
export const BRAND_DETAILS_SUCCESS = 'BRAND_DETAILS_SUCCESS';
export const BRAND_DETAILS_FAILURE = 'BRAND_DETAILS_FAILURE';

export const GET_BRAND_LATEST_STORY_REQUEST = 'GET_BRAND_LATEST_STORY_REQUEST';
export const GET_BRAND_LATEST_STORY_SUCCESS = 'GET_BRAND_LATEST_STORY_SUCCESS';
export const GET_BRAND_LATEST_STORY_FAILURE = 'GET_BRAND_LATEST_STORY_FAILURE';

export const GET_BRAND_LATEST_POST_REQUEST = 'GET_BRAND_LATEST_POST_REQUEST';
export const GET_BRAND_LATEST_POST_SUCCESS = 'GET_BRAND_LATEST_POST_SUCCESS';
export const GET_BRAND_LATEST_POST_FAILURE = 'GET_BRAND_LATEST_POST_FAILURE';

export const QR_SCAN_COUNT_REQUEST = 'QR_SCAN_COUNT_REQUEST';
export const QR_SCAN_COUNT_SUCCESS = 'QR_SCAN_COUNT_SUCCESS';
export const QR_SCAN_COUNT_FAILURE = 'QR_SCAN_COUNT_FAILURE';


export const BrandAction = {

    /**
     * Action for get brand details based on subdomain
     * @param {sting} subdomain - Subdomain of the brand from url
     * @author Akshay 
     */
    getBrandDetails: (subdomain) => {
        return dispatch => {
            dispatch(request(subdomain));
            return BrandServices.getBrandDetails(subdomain)
                .then(
                    response => { return dispatch(success(subdomain, response)); },
                    error => { return dispatch(failure(subdomain, error)); }
                );
        };

        function request(request) { return { type: BRAND_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: BRAND_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BRAND_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get brand's latest deal details
     * @param {string} subdomain - Subdomain of the brand
     * @author Akshay 
     */
    getBrandLatestStory: (subdomain) => {
        return dispatch => {
            dispatch(request());
            return BrandServices.getBrandLatestStory(subdomain)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: GET_BRAND_LATEST_STORY_REQUEST } }
        function success(response) { return { type: GET_BRAND_LATEST_STORY_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GET_BRAND_LATEST_STORY_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get brand's latest deal details
     * @param {string} subdomain - Subdomain of the brand
     * @author Akshay 
     */
    getBrandRecentPost: (subdomain) => {
        return dispatch => {
            dispatch(request());
            return BrandServices.getBrandRecentPost(subdomain)
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: GET_BRAND_LATEST_POST_REQUEST } }
        function success(response) { return { type: GET_BRAND_LATEST_POST_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GET_BRAND_LATEST_POST_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    qrScanCount: (params) => {
        return dispatch => {
            dispatch(request(params));
            return BrandServices.qrScanCount(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: QR_SCAN_COUNT_REQUEST, request: request } }
        function success(request, response) { return { type: QR_SCAN_COUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: QR_SCAN_COUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};
