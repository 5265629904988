import { createTheme } from "@mui/material";
import { primaryColor } from "./theme_variable";

const theme = createTheme({
    palette: {
        mode: 'light',
        background: {
            // default: "#222222"
        },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: primaryColor[0]
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        error: {
            // light: will be calculated from palette.primary.main,
            main: '#ff4400'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        text: {
            // primary: secondaryColor[0],
            // secondary: primaryColor[0],
        },
        gray: {
            main: '#999'
        }
    },
    typography: {
        fontFamily: ['Roboto'],
        fontWeightBold: 900,
        h1: {
            fontWeight: 500
        },
        h2: {
            fontWeight: 500
        },
        h3: {
            fontWeight: 500
        },
        h4: {
            fontWeight: 500
        },
        h5: {
            fontWeight: 500
        },
        h6: {
            fontWeight: 500
        },
    },
    zIndex: {
        appBar: 1201,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 1200,
            xl: 1400,
        },
    },
});

export { theme };