import React from 'react'
import { Card as MuiCard } from '@mui/material'

function Card({ ...props }) {
    return (
        <MuiCard {...props}>{props.children} </MuiCard>
    )
}

export { Card }
