import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    giveawayTimerBox: {
        justifyContent: "center",
        alignItems: "center",
        '& .MuiList-root': {
            display: 'flex',
            flexDirection: 'row',
            fontSize: `1em`,
            listStyleType: `none`,
            padding: `0.5em`,
            '& .MuiListItem-root': {
                display: `inline-block`,
                fontSize: `1em`,
                listStyleType: `none`,
                justifyContent: `center`,
                textAlign: `center`
            },
            '& span': {
                display: `block`,
                fontSize: `1.5rem`
            }
        }
    }
}))

export { useStyles };