import { CircularProgress } from '@mui/material';
import React from 'react';
import './initial-loader.scss';

function InitialLoader({ loader }) {

    if (!loader) {
        return '';
    }
    return (
        <div className={`initial-loader`}>
            <div className="initial-loader-inner" >
                <CircularProgress />
            </div>
        </div>
    )
}

export { InitialLoader };
