export const INITIAL_LOADER = 'INITIAL_LOADER';
export const PAGE_LOADER = 'PAGE_LOADER';
export const SET_DRAWER = 'SET_DRAWER';
export const SET_TOAST = 'SET_TOAST';

export const GlobalAction = {

    /**
     * Action for showing initial loader
     * @author Akshay 
     */
    showInitialLoader: () => {
        return { type: INITIAL_LOADER, loader: true }
    },

    /**
     * Action for hiding initial loader
     * @author Akshay 
     */
    hideInitialLoader: () => {
        return { type: INITIAL_LOADER, loader: false }
    },

    /**
     * Action for showing page loader
     * @author Akshay 
     */
    showPageLoader: () => {
        return { type: PAGE_LOADER, loader: true }
    },

    /**
     * Action for hiding page loader
     * @author Akshay 
     */
    hidePageLoader: () => {
        return { type: PAGE_LOADER, loader: false }
    },

    /**
     * Action for setting drawer
     * @author Akshay 
     */
    setDrawer: (payload) => {
        return { type: SET_DRAWER, isOpen: payload }
    },
    /**
     * Action for showing notification message
     * @author Akshay 
     */
    showToastMessage: (message) => {
        return { type: SET_TOAST, message: message }
    }


};





