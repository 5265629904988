
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    cardContent: {
        padding: `${theme.spacing(6)}!important`,
        "&:last-child": {
            paddingBottom: `${theme.spacing(6)}!important`,
        }
    }
}))

export { useStyles };