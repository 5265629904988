import {
	SR_VERIFY_INSTA_HANDLE_REQUEST, SR_VERIFY_INSTA_HANDLE_SUCCESS, SR_VERIFY_INSTA_HANDLE_FAILURE,

	VERIFY_INSTA_HANDLE_REQUEST, VERIFY_INSTA_HANDLE_SUCCESS, VERIFY_INSTA_HANDLE_FAILURE,
	VERIFY_SHOPPER_LIKED_REQUEST, VERIFY_SHOPPER_LIKED_SUCCESS, VERIFY_SHOPPER_LIKED_FAILURE,

	VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS, VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST, VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE,

	LER_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST, LER_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS, LER_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE,
	LER_SET_EMAIL_REQUEST, LER_SET_EMAIL_SUCCESS, LER_SET_EMAIL_FAILURE,

	SLEG_VERIFY_INSTA_HANDLE_REQUEST, SLEG_VERIFY_INSTA_HANDLE_SUCCESS, SLEG_VERIFY_INSTA_HANDLE_FAILURE,
	SLEG_VERIFY_SHOPPER_LIKED_REQUEST, SLEG_VERIFY_SHOPPER_LIKED_SUCCESS, SLEG_VERIFY_SHOPPER_LIKED_FAILURE,
	SLEG_SET_EMAIL_REQUEST, SLEG_SET_EMAIL_SUCCESS, SLEG_SET_EMAIL_FAILURE,

	SEG_VERIFY_INSTA_HANDLE_REQUEST, SEG_VERIFY_INSTA_HANDLE_SUCCESS, SEG_VERIFY_INSTA_HANDLE_FAILURE,
	SEG_SET_EMAIL_REQUEST, SEG_SET_EMAIL_SUCCESS, SEG_SET_EMAIL_FAILURE,

	LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS, LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST, LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE,
	LEG_SET_EMAIL_REQUEST, LEG_SET_EMAIL_SUCCESS, LEG_SET_EMAIL_FAILURE,

	SLER_VERIFY_INSTA_HANDLE_REQUEST, SLER_VERIFY_INSTA_HANDLE_SUCCESS, SLER_VERIFY_INSTA_HANDLE_FAILURE,
	SLER_VERIFY_SHOPPER_LIKED_REQUEST, SLER_VERIFY_SHOPPER_LIKED_SUCCESS, SLER_VERIFY_SHOPPER_LIKED_FAILURE,
	SLER_SET_EMAIL_REQUEST, SLER_SET_EMAIL_SUCCESS, SLER_SET_EMAIL_FAILURE,

	SER_VERIFY_INSTA_HANDLE_REQUEST, SER_VERIFY_INSTA_HANDLE_SUCCESS, SER_VERIFY_INSTA_HANDLE_FAILURE,
	SER_SET_EMAIL_REQUEST, SER_SET_EMAIL_SUCCESS, SER_SET_EMAIL_FAILURE,

} from "../actions";

const initialState = {
	sr_verify_insta_loading: false, sr_verify_insta_request: {}, sr_verify_insta_response: {}, sr_verify_insta_error: {},

	verify_insta_loading: false, verify_insta_request: {}, verify_insta_response: {}, verify_insta_error: {},
	verify_like_loading: false, verify_like_request: {}, verify_like_response: {}, verify_like_error: {},

	verify_recent_post_like_loading: false, verify_recent_post_like_request: {}, verify_recent_post_like_response: {}, verify_recent_post_like_error: {},

	ler_verify_recent_post_like_loading: false, ler_verify_recent_post_like_request: {}, ler_verify_recent_post_like_response: {}, ler_verify_recent_post_like_error: {},
	ler_set_email_loading: false, ler_set_email_request: {}, ler_set_email_response: {}, ler_set_email_error: {},

	sleg_verify_insta_loading: false, sleg_verify_insta_request: {}, sleg_verify_insta_response: {}, sleg_verify_insta_error: {},
	sleg_verify_like_loading: false, sleg_verify_like_request: {}, sleg_verify_like_response: {}, sleg_verify_like_error: {},
	sleg_set_email_loading: false, sleg_set_email_request: {}, sleg_set_email_response: {}, sleg_set_email_error: {},

	seg_verify_insta_loading: false, seg_verify_insta_request: {}, seg_verify_insta_response: {}, seg_verify_insta_error: {},
	seg_set_email_loading: false, seg_set_email_request: {}, seg_set_email_response: {}, seg_set_email_error: {},

	leg_set_email_loading: false, leg_set_email_request: {}, leg_set_email_response: {}, leg_set_email_error: {},

	sler_verify_insta_loading: false, sler_verify_insta_request: {}, sler_verify_insta_response: {}, sler_verify_insta_error: {},
	ser_set_email_loading: false, ser_set_email_request: {}, ser_set_email_response: {}, ser_set_email_error: {}

};

export function FindMyStoryReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Sign in redux */
		case SR_VERIFY_INSTA_HANDLE_REQUEST: {
			return {
				...state,
				sr_verify_insta_loading: true,
				sr_verify_insta_request: action.request,
				sr_verify_insta_error: {}
			};
		}
		case SR_VERIFY_INSTA_HANDLE_SUCCESS: {
			return {
				...state,
				sr_verify_insta_loading: false,
				sr_verify_insta_response: action.data,
			};
		}
		case SR_VERIFY_INSTA_HANDLE_FAILURE: {
			return {
				...state,
				sr_verify_insta_loading: false,
				sr_verify_insta_error: action.errors,
			};
		}
		/** end::Sign in redux */

		/** begin::Sign in redux */
		case VERIFY_INSTA_HANDLE_REQUEST: {
			return {
				...state,
				verify_insta_loading: true,
				verify_insta_request: action.request,
				verify_insta_error: {}
			};
		}
		case VERIFY_INSTA_HANDLE_SUCCESS: {
			return {
				...state,
				verify_insta_loading: false,
				verify_insta_response: action.data,
			};
		}
		case VERIFY_INSTA_HANDLE_FAILURE: {
			return {
				...state,
				verify_insta_loading: false,
				verify_insta_error: action.errors,
			};
		}
		/** end::Sign in redux */

		/** begin::Sign in redux */
		case VERIFY_SHOPPER_LIKED_REQUEST: {
			return {
				...state,
				verify_like_loading: true,
				verify_like_request: action.request,
				verify_like_error: {}
			};
		}
		case VERIFY_SHOPPER_LIKED_SUCCESS: {
			return {
				...state,
				verify_like_loading: false,
				verify_like_response: action.data,
			};
		}
		case VERIFY_SHOPPER_LIKED_FAILURE: {
			return {
				...state,
				verify_like_loading: false,
				verify_like_error: action.errors,
			};
		}
		/** end::Sign in redux */

		case SER_VERIFY_INSTA_HANDLE_REQUEST: {
			return {
				...state,
				ser_verify_insta_loading: true,
				ser_verify_insta_request: action.request,
				ser_verify_insta_error: {}
			};
		}
		case SER_VERIFY_INSTA_HANDLE_SUCCESS: {
			return {
				...state,
				ser_verify_insta_loading: false,
				ser_verify_insta_response: action.data,
			};
		}
		case SER_VERIFY_INSTA_HANDLE_FAILURE: {
			return {
				...state,
				ser_verify_insta_loading: false,
				ser_verify_insta_error: action.errors,
			};
		}
		case SER_SET_EMAIL_REQUEST: {
			return {
				...state,
				ser_set_email_loading: true,
				ser_set_email_request: action.request,
				ser_set_email_error: {}
			};
		}
		case SER_SET_EMAIL_SUCCESS: {
			return {
				...state,
				ser_set_email_loading: false,
				ser_set_email_response: action.data,
			};
		}
		case SER_SET_EMAIL_FAILURE: {
			return {
				...state,
				ser_set_email_loading: false,
				ser_set_email_error: action.errors,
			};
		}







		case SLER_VERIFY_INSTA_HANDLE_REQUEST: {
			return {
				...state,
				sler_verify_insta_loading: true,
				sler_verify_insta_request: action.request,
				sler_verify_insta_error: {}
			};
		}
		case SLER_VERIFY_INSTA_HANDLE_SUCCESS: {
			return {
				...state,
				sler_verify_insta_loading: false,
				sler_verify_insta_response: action.data,
			};
		}
		case SLER_VERIFY_INSTA_HANDLE_FAILURE: {
			return {
				...state,
				sler_verify_insta_loading: false,
				sler_verify_insta_error: action.errors,
			};
		}
		case SLER_VERIFY_SHOPPER_LIKED_REQUEST: {
			return {
				...state,
				sler_verify_like_loading: true,
				sler_verify_like_request: action.request,
				sler_verify_like_error: {}
			};
		}
		case SLER_VERIFY_SHOPPER_LIKED_SUCCESS: {
			return {
				...state,
				sler_verify_like_loading: false,
				sler_verify_like_response: action.data,
			};
		}
		case SLER_VERIFY_SHOPPER_LIKED_FAILURE: {
			return {
				...state,
				sler_verify_like_loading: false,
				sler_verify_like_error: action.errors,
			};
		}
		case SLER_SET_EMAIL_REQUEST: {
			return {
				...state,
				sler_set_email_loading: true,
				sler_set_email_request: action.request,
				sler_set_email_error: {}
			};
		}
		case SLER_SET_EMAIL_SUCCESS: {
			return {
				...state,
				sler_set_email_loading: false,
				sler_set_email_response: action.data,
			};
		}
		case SLER_SET_EMAIL_FAILURE: {
			return {
				...state,
				sler_set_email_loading: false,
				sler_set_email_error: action.errors,
			};
		}














		/** begin::Sign in redux */
		case VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST: {
			return {
				...state,
				verify_recent_post_like_loading: true,
				verify_recent_post_like_request: action.request,
				verify_recent_post_like_error: {}
			};
		}
		case VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS: {
			return {
				...state,
				verify_recent_post_like_loading: false,
				verify_recent_post_like_response: action.data,
			};
		}
		case VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE: {
			return {
				...state,
				verify_recent_post_like_loading: false,
				verify_recent_post_like_error: action.errors,
			};
		}
		/** end::Sign in redux */

		/** begin::Sign in redux */
		case LER_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST: {
			return {
				...state,
				ler_verify_recent_post_like_loading: true,
				ler_verify_recent_post_like_request: action.request,
				ler_verify_recent_post_like_error: {}
			};
		}
		case LER_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS: {
			return {
				...state,
				ler_verify_recent_post_like_loading: false,
				ler_verify_recent_post_like_response: action.data,
			};
		}
		case LER_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE: {
			return {
				...state,
				ler_verify_recent_post_like_loading: false,
				ler_verify_recent_post_like_error: action.errors,
			};
		}
		case LER_SET_EMAIL_REQUEST: {
			return {
				...state,
				ler_set_email_loading: true,
				ler_set_email_request: action.request,
				ler_set_email_error: {}
			};
		}
		case LER_SET_EMAIL_SUCCESS: {
			return {
				...state,
				ler_set_email_loading: false,
				ler_set_email_response: action.data,
			};
		}
		case LER_SET_EMAIL_FAILURE: {
			return {
				...state,
				ler_set_email_loading: false,
				ler_set_email_error: action.errors,
			};
		}
		/** end::Sign in redux */

		/** begin::Sign in redux */
		case SLEG_VERIFY_INSTA_HANDLE_REQUEST: {
			return {
				...state,
				sleg_verify_insta_loading: true,
				sleg_verify_insta_request: action.request,
				sleg_verify_insta_error: {}
			};
		}
		case SLEG_VERIFY_INSTA_HANDLE_SUCCESS: {
			return {
				...state,
				sleg_verify_insta_loading: false,
				sleg_verify_insta_response: action.data,
			};
		}
		case SLEG_VERIFY_INSTA_HANDLE_FAILURE: {
			return {
				...state,
				sleg_verify_insta_loading: false,
				sleg_verify_insta_error: action.errors,
			};
		}
		/** end::Sign in redux */

		/** begin::Sign in redux */
		case SLEG_VERIFY_SHOPPER_LIKED_REQUEST: {
			return {
				...state,
				sleg_verify_like_loading: true,
				sleg_verify_like_request: action.request,
				sleg_verify_like_error: {}
			};
		}
		case SLEG_VERIFY_SHOPPER_LIKED_SUCCESS: {
			return {
				...state,
				sleg_verify_like_loading: false,
				sleg_verify_like_response: action.data,
			};
		}
		case SLEG_VERIFY_SHOPPER_LIKED_FAILURE: {
			return {
				...state,
				sleg_verify_like_loading: false,
				sleg_verify_like_error: action.errors,
			};
		}
		/** end::Sign in redux */
		/** begin::Sign in redux */
		case SLEG_SET_EMAIL_REQUEST: {
			return {
				...state,
				sleg_set_email_loading: true,
				sleg_set_email_request: action.request,
				sleg_set_email_error: {}
			};
		}
		case SLEG_SET_EMAIL_SUCCESS: {
			return {
				...state,
				sleg_set_email_loading: false,
				sleg_set_email_response: action.data,
			};
		}
		case SLEG_SET_EMAIL_FAILURE: {
			return {
				...state,
				sleg_set_email_loading: false,
				sleg_set_email_error: action.errors,
			};
		}
		/** end::Sign in redux */
		/** begin::Sign in redux */
		case SEG_VERIFY_INSTA_HANDLE_REQUEST: {
			return {
				...state,
				seg_verify_insta_loading: true,
				seg_verify_insta_request: action.request,
				seg_verify_insta_error: {}
			};
		}
		case SEG_VERIFY_INSTA_HANDLE_SUCCESS: {
			return {
				...state,
				seg_verify_insta_loading: false,
				seg_verify_insta_response: action.data,
			};
		}
		case SEG_VERIFY_INSTA_HANDLE_FAILURE: {
			return {
				...state,
				seg_verify_insta_loading: false,
				seg_verify_insta_error: action.errors,
			};
		}
		/** end::Sign in redux */

		/** begin::Sign in redux */
		case SEG_SET_EMAIL_REQUEST: {
			return {
				...state,
				seg_set_email_loading: true,
				seg_set_email_request: action.request,
				seg_set_email_error: {}
			};
		}
		case SEG_SET_EMAIL_SUCCESS: {
			return {
				...state,
				seg_set_email_loading: false,
				seg_set_email_response: action.data,
			};
		}
		case SEG_SET_EMAIL_FAILURE: {
			return {
				...state,
				seg_set_email_loading: false,
				seg_set_email_error: action.errors,
			};
		}
		/** end::Sign in redux */

		/** begin::Sign in redux */
		case LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST: {
			return {
				...state,
				leg_verify_recent_post_like_loading: true,
				leg_verify_recent_post_like_request: action.request,
				leg_verify_recent_post_like_error: {}
			};
		}
		case LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS: {
			return {
				...state,
				leg_verify_recent_post_like_loading: false,
				leg_verify_recent_post_like_response: action.data,
			};
		}
		case LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE: {
			return {
				...state,
				leg_verify_recent_post_like_loading: false,
				leg_verify_recent_post_like_error: action.errors,
			};
		}
		/** end::Sign in redux */
		/** begin::Sign in redux */
		case LEG_SET_EMAIL_REQUEST: {
			return {
				...state,
				leg_set_email_loading: true,
				leg_set_email_request: action.request,
				leg_set_email_error: {}
			};
		}
		case LEG_SET_EMAIL_SUCCESS: {
			return {
				...state,
				leg_set_email_loading: false,
				leg_set_email_response: action.data,
			};
		}
		case LEG_SET_EMAIL_FAILURE: {
			return {
				...state,
				leg_set_email_loading: false,
				leg_set_email_error: action.errors,
			};
		}
		/** end::Sign in redux */

		default:
			return state;
	}
}
