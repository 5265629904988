import React from 'react';
import './loader.scss';

function PageLoader({ loader }) {

    if (!loader) {
        return '';
    }
    return (
        <div className={`full-loader`}>
            <div className="loader-inner" >
                <div className="loader-line-wrap">
                    <div className="loader-line"></div>
                </div>
                <div className="loader-line-wrap">
                    <div className="loader-line"></div>
                </div>
                <div className="loader-line-wrap">
                    <div className="loader-line"></div>
                </div>
            </div>
        </div>
    )
}

export { PageLoader };
