import React from 'react';
import { connect } from 'react-redux';
import { Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/system';


// Styled component for the left grid 
const RightGridItem = styled(({ ...props }) => (<Grid item display="flex" justifyContent="center" alignItems="center" {...props} />))`
        & .MuiGrid-item {
            background: #fff; 
        }
        & .story_image {
            max-width:400px;
            ${props => props.theme.breakpoints.down(800)} {
                max-width:350px;
            }
            ${props => props.theme.breakpoints.down(700)} {
                max-width:100%;
            }
        }
`;

function StorySection({ loading, src, alt, brand, ...props }) {

    if (loading) {
        return (
            <RightGridItem brand={brand} >
                <Grid container spacing={0} sx={{ height: `100%` }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" alignContent="center" justifyContent="center" >
                        <Skeleton width={400} height={400} animation="wave" />
                    </Grid>
                </Grid>
            </RightGridItem>
        )
    }
    if (src) {
        return (
            <RightGridItem brand={brand} >
                <Grid container spacing={0} sx={{ height: `100%` }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" alignContent="center" justifyContent="center" >
                        <img src={src} alt={alt} width="100%" className='story_image' />
                    </Grid>
                </Grid>
            </RightGridItem>
        )
    } else {
        return '';
    }
}


const mapStateToProps = (state) => ({
    brand: state.BrandReducer.brand,
})

const connectedStorySection = connect(mapStateToProps, null)(StorySection);
export { connectedStorySection as StorySection };

