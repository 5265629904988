
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    leftGrid: {
        // padding: `80px`,
        padding: `40px`,
        "@media (max-width: 768px)": {
            padding: '30px'
        }
    },
    leftFormBox: {
        padding: `20px 60px`,
        "@media (max-width: 1366px)": {
            padding: '15px 0'
        }
    },
    brandLogo: {
        maxWidth: '200px',
        height: `unset`
    }
}))

export { useStyles };