import { Box } from '@mui/system'
import React from 'react'
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography'

function FinePrint({ brand, href = "https://www.instagram.com/", alt = 'instagram icon' }) {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" pt={4} >
            <Typography variant="body2" color="initial" style={{ wordBreak: `break-word` }} dangerouslySetInnerHTML={{ __html: brand.offer_description }} />
        </Box>
    )
}


const mapStateToProps = (state) => ({
    brand: state.BrandReducer.brand,
})

const connectedFinePrint = connect(mapStateToProps, null)(FinePrint);
export { connectedFinePrint as FinePrint };

