import React from 'react'
import { CardContent as MuiCardContent } from '@mui/material'
import { useStyles } from '.'


function CardContent({ ...props }) {

    const classes = useStyles()

    return (
        <MuiCardContent className={classes.cardContent} {...props}>{props.children}</MuiCardContent>
    )
}

export { CardContent }
