import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { BrandAction, GlobalAction } from './redux/actions';
import { PreAuthMainComponents } from './routes';

import { PageLoader } from './_layouts/loader/page-loader/PageLoader';
import { InitialLoader } from './_layouts/loader/initial-loader/InitialLoader';
import { Toast } from './_layouts/toast';
import { AppNameContext } from './_contexts';
import { NotFound } from './app/not-found';
import './App.scss'


function App({ toast, brand, pageLoader, initialLoader, getBrandDetails, hideInitialLoader }) {

  const history = useHistory();
  const menuRoutes = Object.values(PreAuthMainComponents());

  useEffect(() => {
    const subdomain = window.location.host.split('.')[0];
    getBrandDetails(subdomain).then((response) => {
      if (response.status === 1 && response.data.is_deal_live) {
        const redirectUrl = `/${(window.location.pathname.split('/')[2]) ? ((window.location.pathname.split('/')[2]) + `/`) : ''}${window.location.search}`
        // const redirectUrl = `/${response.data.flow_method}/${(window.location.pathname.split('/')[2]) ? ((window.location.pathname.split('/')[2]) + `/`) : ''}${window.location.search}`
        history.push(redirectUrl);
      } else {
        return window.location.href = "https://guru.club/forbusinesses/";
      }
      hideInitialLoader();
    });

    //eslint-disable-next-line
  }, []);

  return (
    <AppNameContext.Provider value={brand.company_name ?? ''}>
      <HelmetProvider>
        <Switch>
          {menuRoutes.map((route, index) => (
            <Route exact key={index} path={route.path} component={route.component} />
          ))}
          <Route component={NotFound} />
        </Switch>
        <PageLoader loader={pageLoader} />
        <InitialLoader loader={initialLoader} />
        <Toast message={toast} />
      </HelmetProvider>
    </AppNameContext.Provider>
  );
}

function mapStateToProps(state) {
  return {
    pageLoader: state.GlobalReducer.pageLoader,
    initialLoader: state.GlobalReducer.initialLoader,
    toast: state.GlobalReducer.toastMessage,
    isBrandLoading: state.BrandReducer.brand_loading,
    brand: state.BrandReducer.brand,
  };
}

function mapDispatchToProps(dispatch) {

  return {
    getBrandDetails: (subdomain) => dispatch(BrandAction.getBrandDetails(subdomain)),
    hideInitialLoader: () => dispatch(GlobalAction.hideInitialLoader())
  }
};



const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };