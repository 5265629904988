
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    Link: {
        fontSize: `14px`,
        textDecoration: `none`,
        color: theme.palette.primary.main
    }
}))

export { useStyles };