import { Grid } from "@mui/material";
import { styled } from "@mui/system";

// Styled component for the left grid 
const PreSignInLeftGridComponent = styled(({ ...props }) => (<Grid item display="flex" justifyContent="center" alignItems="center" {...props} />))`
        background: ${props => props.brand.primary_color_hex};
        max-width:520px;
        ${props => props.theme.breakpoints.down(985)} {
            max-width:400px;
        }
        ${props => props.theme.breakpoints.down(800)} {
            max-width:350px;
        }
        ${props => props.theme.breakpoints.down(700)} {
            width:100%;
            max-width:100%;
        }
        min-height:711px;
        & .MuiTypography-root {
            color: ${props => props.brand.secondary_color_hex};
            ${'' /* font-family:  ${props => props.brand.font}; */}
        },
        & .MuiTypography-h6 {
            line-height: 1.2;
        },
        & .MuiButton-contained {
            background-color: ${props => props.brand.link_color_hex};
            border-color:${props => props.brand.primary_color_hex};
            color:  ${props => props.brand.primary_color_hex};
            border: 1px solid ${props => props.brand.link_color_hex};
            box-shadow: none;
            &:hover {
                background-color:${props => props.brand.primary_color_hex};
                border: 1px solid ${props => props.brand.link_color_hex};
                color:  ${props => props.brand.link_color_hex};
                box-shadow: none;
            },
            &:active {
                background-color: '#0062cc';
                border-color: '#005cbf';
            },
            &:focus {
                box-shadow: '0 0 0 0.2rem rgba(0,123,255,.5)';
            },
        },
        & .MuiLink-root {
            color: ${props => props.brand.link_color_hex};
        },
        & .MuiListItem-root {
            color: ${props => props.brand.secondary_color_hex};
        },
        & .MuiFormControl-root {
            & input{
                color: ${props => props.brand.secondary_color_hex};
                -webkit-text-fill-color:${props => `${props.brand.secondary_color_hex}`};
                -webkit-box-shadow: ${props => `0 0 0 1000px ${props.brand.primary_color_hex} inset`};
                &:-webkit-autofill{
                    color: ${props => props.brand.secondary_color_hex};
                    -webkit-text-fill-color:${props => `${props.brand.secondary_color_hex}`};
                    -webkit-box-shadow: ${props => `0 0 0 1000px ${props.brand.primary_color_hex} inset`};
                }
                &:-webkit-autofill:focus{
                    color: ${props => props.brand.secondary_color_hex};
                    -webkit-text-fill-color:${props => `${props.brand.secondary_color_hex}`};
                    -webkit-box-shadow: ${props => `0 0 0 1000px ${props.brand.primary_color_hex} inset`};
                }
            },
            & .MuiSvgIcon-root{
                color: ${props => props.brand.secondary_color_hex};
            },
            & label{
                color: ${props => props.brand.secondary_color_hex};
            },
            & label.Mui-focused {
                color: ${props => props.brand.link_color_hex};
            },
            & .MuiOutlinedInput-root {
                & fieldset {
                    border-color: ${props => props.brand.secondary_color_hex};
                },
                &:hover fieldset {
                    border-color: ${props => props.brand.link_color_hex};
                },
                & .Mui-focused fieldset {
                    border-color:  ${props => props.brand.link_color_hex};
                }
            },
        },
  `;

export { PreSignInLeftGridComponent }