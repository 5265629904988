import React, { useEffect, useState } from 'react'
import moment from 'moment';
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system';
import { Grid, List, ListItem } from '@mui/material';
import { useStyles } from '.';
import { styled } from '@mui/styles';
import { connect } from 'react-redux';

var momentDurationFormatSetup = require("moment-duration-format");

momentDurationFormatSetup(moment);


// Styled component for the left grid 
const GiveawayGrid = styled(({ ...props }) => (<Grid container direction="row" justifyContent="center" alignItems="center" {...props} />))`
        color: ${props => props.brand.link_color_hex};
`;


function GiveawayTimer({ endTime, brand }) {

    const classes = useStyles();

    const calculateTimeLeft = () => {

        let timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };

        const startDate = moment(new Date());
        const timeEnd = moment(new Date(endTime));

        const diff = timeEnd.diff(startDate);
        if (diff > 0) {
            const diffDuration = moment.duration(diff, "milliseconds").format("d,h,m,s");
            const diffDurationSplit = diffDuration.split(',').reverse();
            timeLeft = {
                days: (diffDurationSplit[3]) ? (diffDurationSplit[3]) : 0,
                hours: diffDurationSplit[2],
                minutes: diffDurationSplit[1],
                seconds: diffDurationSplit[0]
            }
        }
        return timeLeft;

    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timerId = setInterval(() => setTimeLeft(calculateTimeLeft()), 1000);
        return () => clearInterval(timerId);
        //eslint-disable-next-line
    }, [endTime])

    if (timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0) {
        return (
            <GiveawayGrid brand={brand} >
                <Grid item textAlign="center" justifyContent="center" className={classes.giveawayTimerBox}>
                    <Typography variant="h4">GIVEAWAY ENDS IN</Typography>
                    <Box>
                        <List >
                            {timeLeft.days > 0 && <ListItem><span>{`${timeLeft.days}`}</span>Days</ListItem>}
                            <ListItem><span>{`${timeLeft.hours}`}</span>Hours</ListItem>
                            <ListItem><span>{`${timeLeft.minutes}`}</span>Minutes</ListItem>
                            <ListItem><span>{`${timeLeft.seconds}`}</span>Seconds</ListItem>
                        </List>
                    </Box>
                </Grid>
            </GiveawayGrid>
        )
    } else {
        return '';
    }
}


const mapStateToProps = (state) => ({
    brand: state.BrandReducer.brand,
})

const connectedGiveawayTimer = connect(mapStateToProps, null)(GiveawayTimer);
export { connectedGiveawayTimer as GiveawayTimer };


