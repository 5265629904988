import React from 'react'
import { connect } from 'react-redux';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { Instagram } from '@mui/icons-material';
import { useStyles, HomeVerifyInstaHandleFormLoader } from '.'
import { PreSignInLeftGridComponent } from '../../../_layouts/pre-signin'
import { BrandLogo, FinePrint, GiveawayTimer } from '../../components';


function HomeVerifyInstaHandleForm({ loading, brand, ...props }) {

    const classes = useStyles();

    if (loading)
        return <HomeVerifyInstaHandleFormLoader />

    return (
        <PreSignInLeftGridComponent className={`${classes.leftGrid}`} brand={brand}>
            <Box>
                <BrandLogo src={brand.logo} alt={brand.name} href={`${brand.company_url} `} />
                <GiveawayTimer endTime={brand.giveaway_date} />
                <Typography variant="body1" align="center" mt={1} mb={2} style={{ wordBreak: `break-word` }} dangerouslySetInnerHTML={{ __html: brand.reward_description }} />
                <Box className={classes.leftFormBox}>
                    <Grid container spacing={1} direction="row" alignItems="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button fullWidth={true} variant="contained" color={"primary"} target="_blank" href={`https://www.instagram.com/${brand.insta_handle}`} sx={{ minWidth: `300px` }}>Go to Instagram</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
                            <IconButton target="_blank" href={`https://www.instagram.com/${brand.insta_handle}`} color="primary">
                                <Instagram style={{ width: '40px', height: '40px', color: `${brand.secondary_color_hex}` }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <FinePrint />
            </Box>
        </PreSignInLeftGridComponent>
    )
}


const mapStateToProps = (state) => ({
    brand: state.BrandReducer.brand,
})


function mapDispatchToProps(dispatch) {
    return {
    }
};

const connectedHomeVerifyInstaHandleForm = connect(mapStateToProps, mapDispatchToProps)(HomeVerifyInstaHandleForm);
export { connectedHomeVerifyInstaHandleForm as HomeVerifyInstaHandleForm };
