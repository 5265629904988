import axios from "axios"
import { cookieServices } from ".";
import { API_ENDPOINT, API_REQUEST_TIMEOUT } from "./config"

const HEADERS = {
    'Content-type': 'application/json',
    'Accept': 'application/json',
    'access-control-allow-origin': '*',
    'access-control-allow-headers': '*'
}

const apiServices = axios.create({
    baseURL: API_ENDPOINT,
    timeout: API_REQUEST_TIMEOUT,
    headers: HEADERS
});

apiServices.interceptors.request.use(
    config => {
        if (cookieServices.get('accessToken')) {
            config.headers.Authorization = `${cookieServices.get('accessToken')}`;
        }
        return config;
    },
    error => { Promise.reject(error) }
);

apiServices.interceptors.response.use(
    (res) => {
        const statusCode = res.status;
        const result = res.data;
        return { errors: result.errors, data: result.data, status_code: statusCode, message: result.message, status: result.status };
    },
    (err) => {
        const response = err.response;
        const statusCode = response.status;
        const result = response.data;
        return Promise.reject({ errors: result.errors, data: result.data, status_code: statusCode, message: result.message, status: result.status });
    }
);

export { apiServices }
