import { FindMyStoryServices } from "../services";

export const SR_VERIFY_INSTA_HANDLE_REQUEST = 'SR_VERIFY_INSTA_HANDLE_REQUEST';
export const SR_VERIFY_INSTA_HANDLE_SUCCESS = 'SR_VERIFY_INSTA_HANDLE_SUCCESS';
export const SR_VERIFY_INSTA_HANDLE_FAILURE = 'SR_VERIFY_INSTA_HANDLE_FAILURE';

export const VERIFY_INSTA_HANDLE_REQUEST = 'VERIFY_INSTA_HANDLE_REQUEST';
export const VERIFY_INSTA_HANDLE_SUCCESS = 'VERIFY_INSTA_HANDLE_SUCCESS';
export const VERIFY_INSTA_HANDLE_FAILURE = 'VERIFY_INSTA_HANDLE_FAILURE';

export const VERIFY_SHOPPER_LIKED_REQUEST = 'VERIFY_SHOPPER_LIKED_REQUEST';
export const VERIFY_SHOPPER_LIKED_SUCCESS = 'VERIFY_SHOPPER_LIKED_SUCCESS';
export const VERIFY_SHOPPER_LIKED_FAILURE = 'VERIFY_SHOPPER_LIKED_FAILURE';

export const SER_VERIFY_INSTA_HANDLE_REQUEST = 'SER_VERIFY_INSTA_HANDLE_REQUEST';
export const SER_VERIFY_INSTA_HANDLE_SUCCESS = 'SER_VERIFY_INSTA_HANDLE_SUCCESS';
export const SER_VERIFY_INSTA_HANDLE_FAILURE = 'SER_VERIFY_INSTA_HANDLE_FAILURE';

export const SER_SET_EMAIL_REQUEST = 'SER_SET_EMAIL_REQUEST';
export const SER_SET_EMAIL_SUCCESS = 'SER_SET_EMAIL_SUCCESS';
export const SER_SET_EMAIL_FAILURE = 'SER_SET_EMAIL_FAILURE';

export const SLER_VERIFY_INSTA_HANDLE_REQUEST = 'SLER_VERIFY_INSTA_HANDLE_REQUEST';
export const SLER_VERIFY_INSTA_HANDLE_SUCCESS = 'SLER_VERIFY_INSTA_HANDLE_SUCCESS';
export const SLER_VERIFY_INSTA_HANDLE_FAILURE = 'SLER_VERIFY_INSTA_HANDLE_FAILURE';

export const SLER_VERIFY_SHOPPER_LIKED_REQUEST = 'SLER_VERIFY_SHOPPER_LIKED_REQUEST';
export const SLER_VERIFY_SHOPPER_LIKED_SUCCESS = 'SLER_VERIFY_SHOPPER_LIKED_SUCCESS';
export const SLER_VERIFY_SHOPPER_LIKED_FAILURE = 'SLER_VERIFY_SHOPPER_LIKED_FAILURE';

export const SLER_SET_EMAIL_REQUEST = 'SLER_SET_EMAIL_REQUEST';
export const SLER_SET_EMAIL_SUCCESS = 'SLER_SET_EMAIL_SUCCESS';
export const SLER_SET_EMAIL_FAILURE = 'SLER_SET_EMAIL_FAILURE';

export const VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST = 'VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST';
export const VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS = 'VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS';
export const VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE = 'VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE';

export const LER_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST = 'LER_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST';
export const LER_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS = 'LER_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS';
export const LER_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE = 'LER_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE';

export const LER_SET_EMAIL_REQUEST = 'LER_SET_EMAIL_REQUEST';
export const LER_SET_EMAIL_SUCCESS = 'LER_SET_EMAIL_SUCCESS';
export const LER_SET_EMAIL_FAILURE = 'LER_SET_EMAIL_FAILURE';

export const SEG_VERIFY_INSTA_HANDLE_REQUEST = 'SEG_VERIFY_INSTA_HANDLE_REQUEST';
export const SEG_VERIFY_INSTA_HANDLE_SUCCESS = 'SEG_VERIFY_INSTA_HANDLE_SUCCESS';
export const SEG_VERIFY_INSTA_HANDLE_FAILURE = 'SEG_VERIFY_INSTA_HANDLE_FAILURE';

export const SEG_SET_EMAIL_REQUEST = 'SEG_SET_EMAIL_REQUEST';
export const SEG_SET_EMAIL_SUCCESS = 'SEG_SET_EMAIL_SUCCESS';
export const SEG_SET_EMAIL_FAILURE = 'SEG_SET_EMAIL_FAILURE';

export const SLEG_VERIFY_INSTA_HANDLE_REQUEST = 'SLEG_VERIFY_INSTA_HANDLE_REQUEST';
export const SLEG_VERIFY_INSTA_HANDLE_SUCCESS = 'SLEG_VERIFY_INSTA_HANDLE_SUCCESS';
export const SLEG_VERIFY_INSTA_HANDLE_FAILURE = 'SLEG_VERIFY_INSTA_HANDLE_FAILURE';

export const SLEG_VERIFY_SHOPPER_LIKED_REQUEST = 'SLEG_VERIFY_SHOPPER_LIKED_REQUEST';
export const SLEG_VERIFY_SHOPPER_LIKED_SUCCESS = 'SLEG_VERIFY_SHOPPER_LIKED_SUCCESS';
export const SLEG_VERIFY_SHOPPER_LIKED_FAILURE = 'SLEG_VERIFY_SHOPPER_LIKED_FAILURE';

export const SLEG_SET_EMAIL_REQUEST = 'SLEG_SET_EMAIL_REQUEST';
export const SLEG_SET_EMAIL_SUCCESS = 'SLEG_SET_EMAIL_SUCCESS';
export const SLEG_SET_EMAIL_FAILURE = 'SLEG_SET_EMAIL_FAILURE';

export const LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST = 'LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST';
export const LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS = 'LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS';
export const LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE = 'LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE';

export const LEG_SET_EMAIL_REQUEST = 'LEG_SET_EMAIL_REQUEST';
export const LEG_SET_EMAIL_SUCCESS = 'LEG_SET_EMAIL_SUCCESS';
export const LEG_SET_EMAIL_FAILURE = 'LEG_SET_EMAIL_FAILURE';

export const FindMyStoryAction = {

    /**
    * Action to ver verify instagram handle
    * @param {Object} params - The params which are used for verify instagram handle
    * @param {string} params.insta_handle - Public instagram handle of the shopper
    * @author Akshay 
    */
    srVerifyInstagramHandle: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.srVerifyInstagramHandle(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SR_VERIFY_INSTA_HANDLE_REQUEST, request: request } }
        function success(request, response) { return { type: SR_VERIFY_INSTA_HANDLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SR_VERIFY_INSTA_HANDLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action to ver verify instagram handle
     * @param {Object} params - The params which are used for verify instagram handle
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    verifyInstagramHandle: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.verifyInstagramHandle(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: VERIFY_INSTA_HANDLE_REQUEST, request: request } }
        function success(request, response) { return { type: VERIFY_INSTA_HANDLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: VERIFY_INSTA_HANDLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to verify that the shopper liked the brands latest post
     * @param {Object} params - The params which are used for check
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    verifyShopperLiked: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.verifyShopperLiked(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: VERIFY_SHOPPER_LIKED_REQUEST, request: request } }
        function success(request, response) { return { type: VERIFY_SHOPPER_LIKED_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: VERIFY_SHOPPER_LIKED_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
    * Action to ver verify instagram handle
    * @param {Object} params - The params which are used for verify instagram handle
    * @param {string} params.insta_handle - Public instagram handle of the shopper
    * @author Akshay 
    */
    serVerifyInstagramHandle: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.serVerifyInstagramHandle(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SER_VERIFY_INSTA_HANDLE_REQUEST, request: request } }
        function success(request, response) { return { type: SER_VERIFY_INSTA_HANDLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SER_VERIFY_INSTA_HANDLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
    * Action to verify that the shopper liked the brands latest post
    * @param {Object} params - The params which are used for check
    * @param {string} params.insta_handle - Public instagram handle of the shopper
    * @author Akshay 
    */
    serSetEmail: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.serSetEmail(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SER_SET_EMAIL_REQUEST, request: request } }
        function success(request, response) { return { type: SER_SET_EMAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SER_SET_EMAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },



    /**
    * Action to ver verify instagram handle
    * @param {Object} params - The params which are used for verify instagram handle
    * @param {string} params.insta_handle - Public instagram handle of the shopper
    * @author Akshay 
    */
    slerVerifyInstagramHandle: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.slerVerifyInstagramHandle(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SLER_VERIFY_INSTA_HANDLE_REQUEST, request: request } }
        function success(request, response) { return { type: SLER_VERIFY_INSTA_HANDLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SLER_VERIFY_INSTA_HANDLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to verify that the shopper liked the brands latest post
     * @param {Object} params - The params which are used for check
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    slerVerifyShopperLiked: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.slerVerifyShopperLiked(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SLER_VERIFY_SHOPPER_LIKED_REQUEST, request: request } }
        function success(request, response) { return { type: SLER_VERIFY_SHOPPER_LIKED_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SLER_VERIFY_SHOPPER_LIKED_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
    * Action to verify that the shopper liked the brands latest post
    * @param {Object} params - The params which are used for check
    * @param {string} params.insta_handle - Public instagram handle of the shopper
    * @author Akshay 
    */
    slerSetEmail: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.slerSetEmail(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SLER_SET_EMAIL_REQUEST, request: request } }
        function success(request, response) { return { type: SLER_SET_EMAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SLER_SET_EMAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action to verify that the shopper liked the brands latest post
     * @param {Object} params - The params which are used for check
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    verifyRecentInstagramPostLiked: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.verifyRecentInstagramPostLiked(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST, request: request } }
        function success(request, response) { return { type: VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to verify that the shopper liked the brands latest post
    * @param {Object} params - The params which are used for check
    * @param {string} params.insta_handle - Public instagram handle of the shopper
    * @author Akshay 
    */
    lerVerifyRecentInstagramPostLiked: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.lerVerifyRecentInstagramPostLiked(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: LER_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST, request: request } }
        function success(request, response) { return { type: LER_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: LER_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action to verify that the shopper liked the brands latest post
     * @param {Object} params - The params which are used for check
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    lerSetEmail: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.lerSetEmail(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: LER_SET_EMAIL_REQUEST, request: request } }
        function success(request, response) { return { type: LER_SET_EMAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: LER_SET_EMAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },




    /**
       * Action to ver verify instagram handle
       * @param {Object} params - The params which are used for verify instagram handle
       * @param {string} params.insta_handle - Public instagram handle of the shopper
       * @author Akshay 
       */
    slegVerifyInstagramHandle: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.slegVerifyInstagramHandle(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SLEG_VERIFY_INSTA_HANDLE_REQUEST, request: request } }
        function success(request, response) { return { type: SLEG_VERIFY_INSTA_HANDLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SLEG_VERIFY_INSTA_HANDLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to verify that the shopper liked the brands latest post
     * @param {Object} params - The params which are used for check
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    slegVerifyShopperLiked: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.slegVerifyShopperLiked(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SLEG_VERIFY_SHOPPER_LIKED_REQUEST, request: request } }
        function success(request, response) { return { type: SLEG_VERIFY_SHOPPER_LIKED_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SLEG_VERIFY_SHOPPER_LIKED_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action to verify that the shopper liked the brands latest post
     * @param {Object} params - The params which are used for check
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    slegSetEmail: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.slegSetEmail(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SLEG_SET_EMAIL_REQUEST, request: request } }
        function success(request, response) { return { type: SLEG_SET_EMAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SLEG_SET_EMAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
       * Action to ver verify instagram handle
       * @param {Object} params - The params which are used for verify instagram handle
       * @param {string} params.insta_handle - Public instagram handle of the shopper
       * @author Akshay 
       */
    segVerifyInstagramHandle: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.segVerifyInstagramHandle(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SEG_VERIFY_INSTA_HANDLE_REQUEST, request: request } }
        function success(request, response) { return { type: SEG_VERIFY_INSTA_HANDLE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SEG_VERIFY_INSTA_HANDLE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to verify that the shopper liked the brands latest post
     * @param {Object} params - The params which are used for check
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    segSetEmail: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.segSetEmail(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: SEG_SET_EMAIL_REQUEST, request: request } }
        function success(request, response) { return { type: SEG_SET_EMAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: SEG_SET_EMAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to verify that the shopper liked the brands latest post
     * @param {Object} params - The params which are used for check
     * @param {string} params.insta_handle - Public instagram handle of the shopper
     * @author Akshay 
     */
    legVerifyRecentInstagramPostLiked: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.legVerifyRecentInstagramPostLiked(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_REQUEST, request: request } }
        function success(request, response) { return { type: LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: LEG_VERIFY_SHOPPER_LIKED_RECENT_POST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action to verify that the shopper liked the brands latest post
    * @param {Object} params - The params which are used for check
    * @param {string} params.insta_handle - Public instagram handle of the shopper
    * @author Akshay 
    */
    legSetEmail: (params) => {
        return dispatch => {
            dispatch(request(params));
            return FindMyStoryServices.legSetEmail(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: LEG_SET_EMAIL_REQUEST, request: request } }
        function success(request, response) { return { type: LEG_SET_EMAIL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: LEG_SET_EMAIL_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


};
