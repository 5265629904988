import React from 'react';

import { Box, Grid, Skeleton } from '@mui/material';
import { useStyles } from '.'

function HomeVerifyInstaHandleFormLoader() {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.leftGrid}  >
                <Grid container  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignContent="center" justifyContent="center">
                        <Skeleton width={200} height={200} animation="wave" />
                    </Grid>
                </Grid>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Box className={classes.leftFormBox}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Skeleton animation="wave" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Skeleton animation="wave" />
                        </Grid>
                    </Grid>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" style={{ marginTop: `10px` }}>
                    <Skeleton animation="wave" />
                </Box>
            </Grid>
        </React.Fragment>
    )
}

export { HomeVerifyInstaHandleFormLoader }
