// import { SrVerifyInstaHandle, SrCouponDetails } from "../app/sr";
// import { SlrVerifyInstaHandle, SlrAuthenticateLike, SlrCouponDetails } from "../app/slr";
// import { SerVerifyInstaHandle, SerEmailDetails, SerCouponDetails } from "../app/ser";
// import { SlerVerifyInstaHandle, SlerAuthenticateLike, SlerEmailDetails, SlerCouponDetails } from "../app/sler";
// import { LrLikeRecentInstagram, LrCouponDetails } from "../app/lr";
// import { LerLikeRecentInstagram, LerEmailDetails, LerCouponDetails } from "../app/ler";
// import { SlegAuthenticateLike, SlegVerifyInstaHandle, SlegEmailDetails, SlegCouponDetails } from "../app/sleg";
// import { SegVerifyInstaHandle, SegEmailDetails, SegCouponDetails } from "../app/seg";
// import { LegCouponDetails, LegEmailDetails, LegLikeRecentInstagram } from "../app/leg";
import { HomeVerifyInstaHandle } from "../app/home";

const allRoutes = {

    'home': {
        path: "/",
        name: "Home",
        component: HomeVerifyInstaHandle,
        layout: "/pre-auth",
        isMainComponent: true
    },
    // 'sr_verify_instagram': {
    //     path: "/sr",
    //     name: "Verify Instagram",
    //     component: SrVerifyInstaHandle,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'sr_coupon_details': {
    //     path: "/sr/coupon",
    //     name: "Coupon Details",
    //     component: SrCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'slr_verify_instagram': {
    //     path: "/slr",
    //     name: "Verify Instagram",
    //     component: SlrVerifyInstaHandle,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'slr_authenticate_like': {
    //     path: "/slr/authenticate",
    //     name: "Authenticate Like",
    //     component: SlrAuthenticateLike,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'slr_coupon_details': {
    //     path: "/slr/coupon",
    //     name: "Coupon Details",
    //     component: SlrCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },

    // 'ser_verify_instagram': {
    //     path: "/ser",
    //     name: "Verify Instagram",
    //     component: SerVerifyInstaHandle,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'ser_get_email': {
    //     path: "/ser/email",
    //     name: "Email Details",
    //     component: SerEmailDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'ser_coupon_details': {
    //     path: "/ser/coupon",
    //     name: "Coupon Details",
    //     component: SerCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },

    // 'sler_verify_instagram': {
    //     path: "/sler",
    //     name: "Verify Instagram",
    //     component: SlerVerifyInstaHandle,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'sler_authenticate_like': {
    //     path: "/sler/authenticate",
    //     name: "Authenticate Like",
    //     component: SlerAuthenticateLike,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'sler_get_email': {
    //     path: "/sler/email",
    //     name: "Email Details",
    //     component: SlerEmailDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'sler_coupon_details': {
    //     path: "/sler/coupon",
    //     name: "Coupon Details",
    //     component: SlerCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },

    // 'lr_like_story': {
    //     path: "/lr",
    //     name: "Verify Instagram",
    //     component: LrLikeRecentInstagram,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'lr_coupon_details': {
    //     path: "/lr/coupon",
    //     name: "Coupon Details",
    //     component: LrCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'ler_like_story': {
    //     path: "/ler",
    //     name: "Verify Instagram",
    //     component: LerLikeRecentInstagram,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'ler_get_email': {
    //     path: "/ler/email",
    //     name: "Email Details",
    //     component: LerEmailDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'ler_coupon_details': {
    //     path: "/ler/coupon",
    //     name: "Coupon Details",
    //     component: LerCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },

    // 'seg_like_story': {
    //     path: "/seg",
    //     name: "Verify Instagram",
    //     component: SegVerifyInstaHandle,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'seg_get_email': {
    //     path: "/seg/email",
    //     name: "Email Details",
    //     component: SegEmailDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'seg_coupon_details': {
    //     path: "/seg/coupon",
    //     name: "Coupon Details",
    //     component: SegCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },

    // 'sleg_like_story': {
    //     path: "/sleg",
    //     name: "Verify Instagram",
    //     component: SlegVerifyInstaHandle,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'sleg_authenticate_like': {
    //     path: "/sleg/authenticate",
    //     name: "Authenticate Like",
    //     component: SlegAuthenticateLike,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'sleg_get_email': {
    //     path: "/sleg/email",
    //     name: "Email Details",
    //     component: SlegEmailDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'sleg_coupon_details': {
    //     path: "/sleg/coupon",
    //     name: "Coupon Details",
    //     component: SlegCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'leg_like_story': {
    //     path: "/leg",
    //     name: "Verify Instagram",
    //     component: LegLikeRecentInstagram,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'leg_get_email': {
    //     path: "/leg/email",
    //     name: "Email Details",
    //     component: LegEmailDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
    // 'leg_coupon_details': {
    //     path: "/leg/coupon",
    //     name: "Coupon Details",
    //     component: LegCouponDetails,
    //     layout: "/pre-auth",
    //     isMainComponent: true
    // },
};

function PreAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PreAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PreAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PreAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}

export { PreAuthRoutes, PreAuthMenuRoutes, PreAuthModuleMenuRoutes, PreAuthMainComponents, getComponentNameByPath };