import { makeStyles } from "@mui/styles";
import { textDanger, textPrimary } from "../../_theme/theme_variable";

const useStyles = makeStyles({
	notFoundWrapper: {
		minHeight: `100vh`,
		'& img': {
			width: `300px`,
			marginBottom: `10px`
		}
	},
	textWrongTurn: {
		...textPrimary,
		marginLeft: `15px`
	},
	textNotFound: {
		...textDanger
	}
})

export { useStyles };
